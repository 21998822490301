<template>
  <BaseListPage locale-section="pages.herDenmark" route="herDenmarkDetail">
    <HerDenmarkList />
  </BaseListPage>
</template>

<script>
export default {
  name: "HerDenmarkPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    HerDenmarkList: () => import("./HerDenmarkList")
  }
};
</script>
